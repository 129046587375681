import { useMemo } from 'react'
import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import thunk from "redux-thunk";
import storage from 'redux-persist/lib/storage'
import { persistReducer } from "redux-persist";
import cart from "./cartSlice";
import notifications from './notificationsSlice';
import createWebStorage from "redux-persist/lib/storage/createWebStorage";

let store

const createNoopStorage = () => {
  return {
    getItem(_key) {
      return Promise.resolve(null);
    },
    setItem(_key, value) {
      return Promise.resolve(value);
    },
    removeItem(_key) {
      return Promise.resolve();
    },
  };
};

//const storage = typeof window !== "undefined" ? createWebStorage("local") : createNoopStorage();
const persistConfig = {
  key: 'root',
  storage
}

//const persistedReducer = persistReducer(persistConfig, reducer)

const reducers = combineReducers({ 
  cart,
  notifications
});

// const config = {
//   key: "root",
//   storage,
// };

const reducer = persistReducer(persistConfig, reducers);

// const store = configureStore({
//   reducer: reducer,
//   devTools: process.env.NODE_ENV !== "production",
//   middleware: [thunk],
// });

function makeStore(initialState = {}) {
  return configureStore({
    reducer: reducer,
    devTools: process.env.NODE_ENV !== "production",
    middleware: [thunk],
    preloadedState: initialState
  })
}


export const initializeStore = (preloadedState) => {
  let _store = store ?? makeStore(preloadedState)

  // After navigating to a page with an initial Redux state, merge that state
  // with the current state in the store, and create a new store
  if (preloadedState && store) {
    _store = makeStore({
      ...store.getState(),
      ...preloadedState,
    })
    // Reset the current store
    store = undefined
  }

  // For SSG and SSR always create a new store
  if (typeof window === 'undefined') return _store
  // Create the store once in the client
  if (!store) store = _store

  return _store
}

export function useStore(initialState) {
  const store = useMemo(() => initializeStore(initialState), [initialState])
  return store
}