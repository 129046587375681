import { useState, forwardRef, useCallback } from "react";
import { useSnackbar, SnackbarContent, CustomContentProps } from "notistack";
import Alert from '@mui/material/Alert';


const NotyCustom = forwardRef((props, ref) => {

    const { closeSnackbar } = useSnackbar();

    const {
        id,
        message,
        persist = true,
        anchorOrigin,
        autoHideDuration,
        hideIconVariant,
        iconVariant,
        variant,
        ...other
    } = props

    const handleDismiss = useCallback(() => {
        closeSnackbar(id);
    }, [id, closeSnackbar]);

    return (
        <SnackbarContent ref={ref} {...anchorOrigin} {...autoHideDuration} {...hideIconVariant} {...iconVariant}>
            <Alert severity={variant} className="w-full rounded-2xl" onClose={handleDismiss}>
                {message}
            </Alert>
        </SnackbarContent>
    )
})

export default NotyCustom