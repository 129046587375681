import axios from 'axios'
import isEmpty from 'lodash/isEmpty';

export function getRedirect(redirectTo, redirectIfFound, user){
  if (!redirectTo || !user) return null;
  if (
    // If redirectTo is set, redirect if the user was not found.
    (redirectTo && !redirectIfFound && !user?.isLoggedIn) ||
    // If redirectIfFound is also set, redirect if the user was found
    (redirectIfFound && user?.isLoggedIn)
  ) {
    if(redirectIfFound && user?.isLoggedIn){
      if((!user.shopTitle || isEmpty(user.shopTitle)) || 
      (!user.shopDescription || isEmpty(user.shopDescription))
      ){
        return {
          redirect: {
            permanent: false,
            destination: "/showcase/profile",
          },
          props:{},
        }
      }else{
        return {
          redirect: {
            permanent: false,
            destination: redirectTo,
          },
          props:{},
        }
      }
    }else{
      return {
        redirect: {
          permanent: false,
          destination: redirectTo,
        },
        props:{},
      }
    }
  }
  return null;
}

async function getShowNoty(req, sNoty){
  let noty = { show: true, date: null }
  const noty_def = req.session?.noty? req.session?.noty : { show: sNoty ? sNoty.show : true, date: sNoty ? sNoty.date : null }
  if(noty_def){
    noty = noty_def
    if(!noty_def.show){
      const diff = (new Date().getTime() - new Date(noty_def.date).getTime()) / 1000 / 3600 / 24
      if(diff >= 0){
        noty.show = true
        noty.date = null
        if(req.session){
          req.session.noty = noty
          await req.session.save();
        }
      }
    }
  }
  return noty
}

export async function getUserSSR(req, redirectTo = "", redirectIfFound = false){
  let userSSR = { isLoggedIn: false, login: "", avatarUrl: "" }
  if(req.session.user){
    let response = { data: null, error: null }
    try {
      let headers = {}
      if(req.session.user.token){
          headers.Authorization = `Bearer ${req.session.user.token}`
      }
      const res = await axios.get(`${process.env.NEXT_PUBLIC_STRAPI_URL}/users/${req.session.user.id}?populate[0]=role&populate[1]=shopLogo&populate[2]=banners.image`, {
          headers
      })
      response.data = res.data;
    } catch (error) {
        if (error.response) {
            if(error.response.data?.error?.message){
                response.error = error.response.data?.error?.message
            }else{
                response.error = error.response.status
            }
            
        } else if (error.request) {
            response.error = error.request
        } else {
            response.error = error.message
        }
    }
    const user = response.data
    if(user){
        const userT = { ...user, isLoggedIn: true, token: req.session.user.token }
        req.session.user = { id: user.id, isLoggedIn: true, token: req.session.user.token };
        userSSR = { ...userT, isLoggedIn: true }
        userSSR.noty = await getShowNoty(req)
        const noty = await getShowNoty(req)
        userSSR = { ...userSSR, noty }
        await req.session.save();
        
    }else{
        req.session.user = null
        await req.session.save();
    }
  }
  return userSSR
}

export async function getShop(req, session){
  const host = req.headers.host
  const hostArray = host.split('.')
  let shop = null
  let prefix = ''
  if(hostArray.length>2){
      prefix = hostArray[0]
  }else{
      prefix = `${process.env.NEXT_DEFAULT_DOMAIN_PREFIX}`
  }
  //Выборка магазина
  let urlShop = `/users?populate[0]=shopLogo&populate[1]=banners.image&filters[shopPrefix][$eq]=${prefix}&filters[isShop][$eq]=true`
  if((prefix === 'cp') && session?.user?.id){
    urlShop = `/users?populate[0]=shopLogo&populate[1]=banners.image&filters[id][$eq]=${session?.user.id}&filters[isShop][$eq]=true`
  }
  let responseShop = { data: null, error: null }
  const responseShopTemp = await axiosTemplate(urlShop, "GET", null, false, null)
  if(responseShopTemp.data && responseShopTemp.data.length > 0){
      responseShop.data = responseShopTemp.data[0]
  }
  responseShop.error = responseShopTemp.error
  if(responseShop.data){
      shop = {
          id: responseShop.data.id,
          type: responseShop.data.type,
          name: responseShop.data.name,
          email: responseShop.data.email,
          phone: responseShop.data.phone,
          address: responseShop.data.address,
          shopPrefix: responseShop.data.shopPrefix,
          shopTitle: responseShop.data.shopTitle,
          shopDescription: responseShop.data.shopDescription,
          shopDescriptionFull: responseShop.data.shopDescriptionFull,
          shopColor: responseShop.data.shopColor,
          telegram: responseShop.data.telegram,
          whatsapp: responseShop.data.whatsapp,
          viber: responseShop.data.viber,
          vk: responseShop.data.vk,
          shopLogo: responseShop.data.shopLogo,
          banners: responseShop.data.banners,
          shop_date_end: responseShop.data.shop_date_end,
          is_shop_blocked: responseShop.data.is_shop_blocked,
          confirmed: responseShop.data.confirmed,
          pays_count: responseShop.data.pays_count,
          shopNameStyle: responseShop.data.shopNameStyle,
          noty: null
      }
      shop.noty = await getShowNoty(req, session?.noty)
  }
  return shop
}

export async function axiosTemplate(url, method, data, local, token){
  let response = { data: null, error: null, status: null }
  let options = {
      method, //'POST'
  }
  if(local){
    options.url = `${url}`
  } else {
    options.url = `${process.env.NEXT_PUBLIC_STRAPI_URL}${url}`
  }
  if(data){
      options.data = data
  }
  if(token){
    options.headers = {
      Authorization: `Bearer ${token}`,
    }
  }
  try {
      const res = await axios(options)
      response.data = res.data;
  } catch (error) {
    if (error.response) {
      if(error.response.data?.error?.message){
          response.error = error.response.data?.error?.message
          response.status = error.response.status
      }else{
          response.error = error.response
          response.status = error.response.status
      }
    } else if (error.request) {
        response.error = error.request
    } else {
        response.error = error.message
    }
  }
  return response
}

export const getError = (nameObj, errorObj) => {
  const errorsObj = []
  if (errorObj instanceof FetchError) {
      // console.log(error.data.message);
      errorsObj.push({name: nameObj, message: errorObj.data.message})
  } else {
      console.log("An unexpected error happened:", errorObj);
      errorsObj.push({name: nameObj, message: 'network error'})
  }
  return errorsObj
}

// "https://xn--e1anu0cb.com/api/v1/auth/login"
export async function awaitFetch(url, body, name){
  const data = { response: null, errors: [] }
  try {
      data.response = await fetchJson(url, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(body),
      })
  } catch (error) {
      data.errors.push(...getError(name, error))
  }
  return data
}

export async function awaitFetchGet(url, body, name){
  const data = { response: null, errors: [] }
  try {
    data.response = await fetchJson(url, {
        method: "GET",
        headers: { "Content-Type": "application/json" }
    })
  } catch (error) {
      data.errors.push(...getError(name, error))
  }
  return data
}

export default async function fetchJson(input, init) {
  const response = await fetch(input, init);
  // if the server replies, there's always some data in json
  // if there's a network error, it will throw at the previous line
  // response.ok is true when res.status is 2xx
  // https://developer.mozilla.org/en-US/docs/Web/API/Response/ok

  if (response.ok) {
    const data = await response.json();
    return data;
  }
  const data = null
  throw new FetchError({
    message: response.statusText,
    response,
    data,
  });
}

export class FetchError extends Error {
  response;
  data;
  constructor({ message, response, data }) {
    // Pass remaining arguments (including vendor specific ones) to parent constructor
    super(message);
    
    // Maintains proper stack trace for where our error was thrown (only available on V8)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, FetchError);
    }

    this.name = "FetchError";
    this.response = response;
    this.data = data ?? { message: message };
  }
}