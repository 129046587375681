import '@/styles/globals.css'
import '@/styles/deviceMorphing.scss'
import '../../public/assets/fonts/uicons/css/bold/rounded.css'
import '../../public/assets/fonts/uicons/css/regular/rounded.css'
import '../../public/assets/fonts/uicons/css/solid/rounded.css'
import '../../public/assets/fonts/uicons/css/brands/all.css'
import * as React from 'react';
import { userAgentFromString } from 'next/server';
import App from 'next/app'
import {useRouter} from "next/router";
import { getIronSession } from 'iron-session'
import { SnackbarProvider } from 'notistack';
import PropTypes from 'prop-types';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { CacheProvider } from '@emotion/react';
import theme from '../theme/theme';
import createEmotionCache from '../theme/createEmotionCache';
import { SWRConfig } from "swr";
import fetchJson, { getShop } from "../../lib/fetchJson";
import { useStore } from '../store'
import { Provider } from 'react-redux'
import { persistStore } from 'redux-persist'
import { PersistGate } from 'redux-persist/integration/react'
import NotyCustom from '@/components/NotyCustom'
import { useScrollRestoration } from 'next-restore-scroll-position';
import { sessionOptions } from '../../lib/session'
import Metrika from '@/components/Metrika'

const clientSideEmotionCache = createEmotionCache();

export default function MyApp(props) {
  const router = useRouter();
  useScrollRestoration(router);
  const { device, user, shop, Component, emotionCache = clientSideEmotionCache, pageProps } = props;
  
  const store = useStore(pageProps.initialReduxState)
  const persistor = persistStore(store, {}, function () {
    persistor.persist()
  })

  return (
    <SWRConfig
        value={{
          fetcher: fetchJson,
          onError: (err) => {
            console.error(err);
          },
        }}
      >
        {!shop && (
          <Metrika />
        )}
        <CacheProvider value={emotionCache}>
          <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
              {() => (
                  <ThemeProvider theme={theme}>
                    {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
                    <CssBaseline />
                    <div className='flex flex-col items-center'>
                      <SnackbarProvider 
                        maxSnack={10} 
                        autoHideDuration={5000}
                        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }} 
                        Components={{
                          success: NotyCustom,
                          error: NotyCustom,
                        }}
                      >
                        {/* <ModalPayments shop={shop} user={user} /> */}
                        <Component {...pageProps} shop={shop} device={device}/>
                      </SnackbarProvider>
                    </div>
                </ThemeProvider>
              )}
            </PersistGate>
          </Provider>
        </CacheProvider>
      </SWRConfig>
  )
}




MyApp.propTypes = {
  Component: PropTypes.elementType.isRequired,
  emotionCache: PropTypes.object,
  pageProps: PropTypes.object.isRequired,
};

MyApp.getInitialProps = async (appContext) => { 
  const pageProps = App.getInitialProps(appContext)
  if (appContext.ctx.req && appContext.ctx.res) {
    const session = await getIronSession(
      appContext.ctx.req,
      appContext.ctx.res,
      sessionOptions
    )

    const { req } = appContext.ctx
    const shop = await getShop(req, session)

    const userAgent = req ? req.headers['user-agent'] : window.navigator.userAgent;

    const device = userAgentFromString(userAgent)?.device.type || 'desktop'

    return {
      ...pageProps,
      shop,
      user: session?.user,
      device
    };
  }

  return pageProps
}
